import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4-4-4-4 to a 4RM`}</p>
    <p>{`Glute Ham Raise 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`15-Goblet Squats (53/35)`}</p>
    <p>{`15-Box Jumps (24/20″)`}</p>
    <p>{`100Ft Bear Crawl`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit Open WOD 18.5 will be tomorrow’s class wod.  Judging will
take place from 9:00-12:00.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      